import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslations } from "use-intl";
import { useIsSmallScreen, useLockBodyScroll } from "../../hooks";
import { Button, Input, RoundedBox, ShortLogo, Text, Title } from "..";
import { FileToUpload } from ".";
import { IconUpload } from "@tabler/icons-react";
import { Album } from "../../types/album.type";
import { Hashtag } from "../../types/hashtag.type";
import { Loader } from "@mantine/core";

// Max size of file allowed
export const ALLOWED_MAX_SIZE = 3500 * 1024 * 1024; // 3500 MB in bytes

interface FileInfo {
  description?: string;
  contributor?: string;
  hashtags?: Hashtag[];
  file: File;
  status: "success" | "error" | "pending";
  statusCode?: number;
  uploadProgress?: number;
}

interface FileUploadDialogProps {
  onClose: () => void;
  files: FileInfo[];
  setFiles: React.Dispatch<React.SetStateAction<FileInfo[]>>;
  loading: boolean;
  album: Album;
  openFileInput: () => void;
  onSubmit: () => void;
  setContributor: React.Dispatch<React.SetStateAction<string>>;
  contributor: string;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  onClose,
  contributor,
  setContributor,
  files,
  openFileInput,
  onSubmit,
  loading,
  setFiles,
  album,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const isSmallScreen = useIsSmallScreen();
  const t = useTranslations("Photos");

  useLockBodyScroll(true);

  const updateContainerWidth = useCallback(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);
    return () => window.removeEventListener("resize", updateContainerWidth);
  }, [updateContainerWidth]);

  const imageDimensions = useMemo(
    () => ({
      width: isSmallScreen
        ? (window.innerWidth - 16 * 3) / 2
        : containerWidth / 3 - 24,
      height: containerWidth / (isSmallScreen ? 2 : 3) - 24,
    }),
    [isSmallScreen, containerWidth]
  );

  const handleRemoveImage = useCallback(
    (indexToRemove: number) => {
      setFiles((currentImages) =>
        currentImages.filter((_, index) => index !== indexToRemove)
      );
      //If only one image is left before removing it, then close dialog
      if (files.length === 1) {
        onClose();
      }
    },
    [files.length, onClose, setFiles]
  );

  const goBack = useMemo(
    () => (
      <Text onClick={onClose} className="underline cursor-pointer my-4">
        {t("backToAlbum")}
      </Text>
    ),
    [onClose, t]
  );

  const tooLargeFiles = useMemo(
    () => files.filter((i) => i.file.size > ALLOWED_MAX_SIZE),
    [files]
  );

  const failedToUploadImages = useMemo(
    () => files.filter((i) => i.status === "error"),
    [files]
  );

  const handleRemoveOfTooLargeFiles = useCallback(() => {
    setFiles((currentFiles) =>
      currentFiles.filter((file) => file.file.size <= ALLOWED_MAX_SIZE)
    );
  }, [setFiles]);

  const hasFailedImages = useMemo(
    () => files.some((i) => i.status === "error"),
    [files]
  );

  const uploadedImages = useMemo(
    () => files.filter((i) => i.status === "success").length,
    [files]
  );

  const handleFileChange = useCallback(
    (index: number, value?: string, hashtags?: Hashtag[]) => {
      setFiles((currentImages) => {
        const newImages = [...currentImages];
        if (value !== undefined) {
          newImages[index].description = value;
        }
        if (hashtags !== undefined) {
          newImages[index].hashtags = hashtags;
        }
        return newImages;
      });
    },
    [setFiles]
  );

  return (
    <div
      id="file-upload-dialog"
      className="fixed inset-0 flex bg-light-gray z-50 overflow-y-auto"
    >
      <div
        ref={containerRef}
        className="w-full max-w-[1200px] px-4 mx-auto flex flex-col"
      >
        <div className="md:h-[72px] h-[60px] flex items-center">
          <ShortLogo />
        </div>

        <div className="w-full">
          {isSmallScreen && goBack}
          <div className="max-w-[700px] mx-auto flex flex-col md:items-center justify-center">
            <Title size="H3">{t("personalTouch")}</Title>
            <Text className="md:text-center mt-2">
              {t("personalTouchDescription")}
            </Text>
            <Input
              className="mt-6 md:w-[400px] w-full"
              value={contributor}
              placeholder={t("yourName")}
              onChange={(e) => setContributor(e.target.value)}
            />
          </div>

          {tooLargeFiles.length > 0 && (
            <RoundedBox color="red" className="mt-6">
              <div className="flex md:flex-row flex-col items-center justify-between">
                <div>
                  <Text className="font-semibold">
                    {t.rich("fileTooBigCount", {
                      count: () => tooLargeFiles.length,
                      total: () => files.length,
                    })}
                  </Text>
                  <Text className="mt-2">{t("fileTooBigDescription")}</Text>
                </div>
                <Button
                  className="mt-4"
                  onClick={handleRemoveOfTooLargeFiles}
                  title={t("remove")}
                />
              </div>
            </RoundedBox>
          )}

          {uploadedImages > 0 && loading && (
            <RoundedBox color="light-green" className="mt-6">
              <Text className="font-semibold flex gap-2 items-center">
                <Loader color="#222" size={24} />
                {t.rich("uploadInProgress", {
                  count: () => uploadedImages,
                  total: () => files.length - tooLargeFiles.length,
                })}
              </Text>
            </RoundedBox>
          )}

          {failedToUploadImages.length > 0 && (
            <RoundedBox color="red" className="mt-6">
              <Text className="font-semibold">
                {t.rich("fileFailedCount", {
                  count: () => failedToUploadImages.length,
                  total: () => files.length - tooLargeFiles.length,
                })}
              </Text>
              <Text className="mt-2">{t("fileFailedDescription")}</Text>
            </RoundedBox>
          )}

          <div className="mt-10 flex md:gap-5 gap-4 flex-wrap pb-32">
            {files.map((image, index) => (
              <FileToUpload
                key={`${image.file.name}-${index}`}
                statusCode={image.statusCode}
                loading={loading}
                uploadProgress={image.uploadProgress}
                status={image.status}
                selectedHashtags={image.hashtags ?? []}
                album={album}
                dimensions={imageDimensions}
                description={image.description ?? ""}
                onRemove={() => handleRemoveImage(index)}
                onDataChange={(value?: string, hashtags?: Hashtag[]) =>
                  handleFileChange(index, value, hashtags)
                }
                file={image.file}
              />
            ))}
          </div>
        </div>
      </div>
      {!loading && (
        <div className="fixed bottom-0 w-screen bg-white z-30">
          <div className="flex h-[60px] items-center justify-between max-w-[1200px] px-4 mx-auto">
            {!isSmallScreen && goBack}
            <div className="flex gap-4 md:justify-end justify-between flex-1">
              <Button
                small
                type="outline"
                title={t("uploadMore")}
                icon={<IconUpload size={16} />}
                onClick={openFileInput}
              />
              <Button
                small
                title={
                  hasFailedImages
                    ? t("reSubmitFailed")
                    : isSmallScreen
                    ? t("submit")
                    : t("submitContribution")
                }
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadDialog;
