import { useTranslations } from "use-intl";
import { Image } from "../../types/image.type";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorService } from "../../services";
import {
  Back,
  BottomToolbar,
  Button,
  HashtagSelector,
  Input,
  MoreActions,
  Text,
  TextArea,
  Title,
} from "..";
import { useEffect, useState } from "react";
import {
  IconArchive,
  IconCheck,
  IconDownload,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import moment from "moment";
import { useAuth } from "../../hooks";
import useImageActions from "../../hooks/useImageActions";
import { Album } from "../../types/album.type";
import { Role } from "../../types/user.type";
import { getImageUrl } from "../../lib/utils";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ImageType } from "../../types/image-type.enum";

const ImageDetails = ({
  onUpdated,
  image,
  onRemoved,
  onClose,
  showDetails,
  album,
}: {
  image: Image;
  onUpdated: (image: Image) => void;
  onRemoved: (id: string) => void;
  onClose: () => void;
  showDetails: boolean;
  album: Album;
}) => {
  const { user } = useAuth();
  const t = useTranslations("Photos");
  const {
    remove,
    unPublish,
    publish,
    approve,
    update,
    loadingUnPublish,
    loadingRemove,
  } = useImageActions(album?.id);
  const [removeOpened, { open: openRemove, close: closeRemove }] =
    useDisclosure(false);

  const disabled =
    user.role !== Role.ADMIN &&
    ((user.isGuest && image.guestUserId !== user.id) ||
      (!user.isGuest && album.user.id !== user.id && user.id !== image.userId)); //Only user who created image or owner of album is allowed to edit caption

  const isAlbumOwnerOrAdmin =
    album.user.id === user.id || user.role === Role.ADMIN;
  const isNotAlbumCreator = user?.id !== album?.user.id;

  const [loading, setLoading] = useState(false);

  //Extract day and month from image date
  const imageDate = moment(image.createdAt);
  const formattedDate = imageDate.format("DD.MM.YYYY");

  const formik = useFormik({
    initialValues: {
      description: image?.description ?? "",
      contributor: image?.contributor ?? "",
      hashtags: [...(image?.hashtagOnImages?.map((h) => h.hashtag.id) ?? [])],
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(2000, t("fieldIsTooLong")),
      contributor: Yup.string().max(200, t("fieldIsTooLong")),
      hashtags: Yup.array().nullable(),
    }),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      update(image.id, values)
        .then((res) => {
          if (res) {
            onUpdated(res);
            onClose();
            ErrorService.showMessage(t("imageUpdated"));
          }
        })
        .catch(() => {
          ErrorService.showError(t("errorUpdating"));
        })
        .finally(() => setLoading(false));
    },
  });

  const handleDownload = () => {
    const imageUrl = getImageUrl(image.url);
    //If image open in tab if video save as
    if (image.type === ImageType.PICTURE) {
      // Open the image URL in a new tab
      window.open(imageUrl, "_blank");
    } else {
      // For videos
      let downloadUrl =
        import.meta.env.VITE_API_URL +
        `/image/download/${album.id}/${image.id}`;
      // If user is guest, add guest user id to download url
      if (user && user.isGuest) {
        downloadUrl = downloadUrl + `?guestUserId=${user.id}`;
      }
      //Open url in new tab
      window.open(downloadUrl, "_blank");
    }
  };

  const handleRemove = async () => {
    try {
      const resp = await remove(image.id);
      if (resp) {
        onRemoved(image.id);
        onClose();
        closeRemove();
      }
    } catch (error) {
      ErrorService.showError(t("errorRemoval"));
    }
  };

  const handleUnPublish = async () => {
    try {
      const resp = await unPublish(image.id);
      if (resp) {
        onUpdated(resp);
        closeRemove();
        onClose();
        ErrorService.showMessage(t("imageUnpublished"));
      }
    } catch (error) {
      ErrorService.showError(t("errorUnpublishing"));
    }
  };

  const handlePublish = async () => {
    try {
      const resp = await publish(image.id);
      if (resp) {
        onUpdated(resp);
        onClose();
        ErrorService.showMessage(t("imagePublished"));
      }
    } catch (error) {
      ErrorService.showError(t("errorPublishing"));
    }
  };

  const handleApprove = async () => {
    try {
      const resp = await approve(image.id);
      if (resp) {
        onUpdated(resp);
        onClose();
        ErrorService.showMessage(t("imageApproved"));
      }
    } catch (error) {
      ErrorService.showError(t("errorApproving"));
    }
  };

  const handleHashtagSelect = (hashtag?: string) => {
    if (disabled) return; //If disabled, do nothing
    const hashtags = formik.values.hashtags ?? [];

    if (hashtags.some((ht) => ht === hashtag)) {
      // If user is not uploader, if user is not admin or owner, if user is not guest user uploaded image, then do nothing
      if (user.id !== image.userId) {
        if (!isAlbumOwnerOrAdmin) {
          if (image?.guestUserId !== user.id) {
            return;
          }
        }
      }
      // If the hashtag is already selected, remove it
      formik.setFieldValue(
        "hashtags",
        hashtags.filter((ht) => ht !== hashtag)
      );
    } else {
      // If the hashtag is not selected, add it to the list
      formik.setFieldValue("hashtags", [...hashtags, hashtag]);
    }
  };

  useEffect(() => {
    formik.resetForm();
  }, [image]);

  return (
    <>
      <BottomToolbar
        opened={showDetails}
        onClose={() => {
          onClose();
        }}
      >
        <div className="flex-1 flex flex-col justify-between mb-6">
          <div>
            <div className="md:hidden flex justify-between mb-4">
              <Title size="H4">
                {disabled ? t("viewDetails") : t("addCaptionOnly")}
              </Title>
              <IconX
                onClick={onClose}
                width={24}
                height={24}
                className="stroke-medium-black"
              />
            </div>
            {disabled ? (
              image.description && (
                <div className="mt-4">
                  <Text size={"sm"} color="gray">
                    {t("caption")}:
                  </Text>
                  <Text className="mt-2">{image.description}</Text>
                </div>
              )
            ) : (
              <TextArea
                disabled={disabled}
                value={formik.values.description}
                name="description"
                onChange={formik.handleChange}
                className="mb-3"
                label={t("caption")}
                error={formik.errors.description}
                minRows={6}
                maxRows={10}
              />
            )}
            {disabled ? (
              image.contributor && (
                <div className="mt-6">
                  <Text size={"sm"} color="gray">
                    {t("contributor")}:
                  </Text>
                  <Text className="mt-2">{image.contributor ?? "-"}</Text>
                </div>
              )
            ) : (
              <Input
                disabled={disabled}
                value={formik.values.contributor}
                name="contributor"
                onChange={formik.handleChange}
                className="mb-3"
                label={t("contributor")}
                error={formik.errors.contributor}
              />
            )}
            {disabled && !image.description && !image.contributor && (
              <div className="mt-6 bg-light-gray rounded-xl">
                <Text color="gray" className="px-4 py-6 text-center">
                  {t("noCaptions")}
                </Text>
              </div>
            )}
          </div>
          {album.hashtags && album.hashtags.length > 0 && (
            <div className="mt-4">
              {!disabled && <Text color="gray">{t("selectHashtags")}:</Text>}
              <div className="mt-4 max-w-[calc(100vw-32px)]">
                <HashtagSelector
                  disabled={disabled}
                  withStroke
                  album={album}
                  onClick={handleHashtagSelect}
                  selectedHashtags={formik.values.hashtags}
                />
              </div>
            </div>
          )}
          <div className="pt-6">
            <div className="mt-4 flex gap-4">
              <MoreActions position="top">
                {!isNotAlbumCreator && (
                  <>
                    {image.published ? (
                      <Text
                        onClick={handleUnPublish}
                        className="flex cursor-pointer font-semibold items-center gap-2"
                      >
                        <IconArchive size={16} className=" stroke-black" />
                        {t("unpublish")}
                      </Text>
                    ) : (
                      <Text
                        onClick={handlePublish}
                        className="flex cursor-pointer font-semibold items-center gap-2"
                      >
                        <IconArchive size={16} className=" stroke-black" />
                        {t("publish")}
                      </Text>
                    )}
                    {!image.approved && (
                      <Text
                        onClick={handleApprove}
                        className="flex cursor-pointer font-semibold items-center gap-2"
                      >
                        <IconCheck size={16} className=" stroke-black" />
                        {t("approve")}
                      </Text>
                    )}
                  </>
                )}
                <Text
                  onClick={handleDownload}
                  className="flex cursor-pointer font-semibold items-center gap-2"
                >
                  <IconDownload size={16} className=" stroke-black" />
                  {t("download")}
                </Text>
                {!disabled && (
                  <Text
                    color="red"
                    onClick={() => {
                      openRemove();
                    }}
                    className="flex cursor-pointer font-semibold items-center gap-2"
                  >
                    <IconTrash size={16} className=" stroke-red" />
                    {t("remove")}
                  </Text>
                )}
              </MoreActions>
              {formik.dirty && (
                <Button
                  loading={loading}
                  onClick={formik.handleSubmit}
                  title={t("save")}
                />
              )}
            </div>
            <div className="mt-6">
              <Text color="gray">
                {t("addedOn")}: {formattedDate}
              </Text>
            </div>
          </div>
        </div>
      </BottomToolbar>
      <Modal
        centered
        radius={8}
        withCloseButton={false}
        opened={removeOpened}
        onClose={closeRemove}
      >
        <Back onClick={closeRemove} />
        <Text color="gray" className="mt-4">
          {isNotAlbumCreator ? t("sureToDelete") : t("deleteOrUnpublish")}
        </Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          {!isNotAlbumCreator && (
            <Button
              loading={loadingUnPublish}
              small
              onClick={handleUnPublish}
              type="outline"
              title={t("unpublish")}
            />
          )}
          <Button
            loading={loadingRemove}
            small
            onClick={handleRemove}
            color="red"
            title={t("remove")}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImageDetails;
