import { useState } from "react";
import { Album } from "../../../types/album.type";
import { IconPlayerPlay } from "@tabler/icons-react";
import moment from "moment/min/moment-with-locales";
import { useLocale, useTranslations } from "use-intl";
import { Button, FileUpload, ShareAlbumModal, Text } from "../../../common";
import { useIsSmallScreen } from "../../../hooks";
import MoreButton from "./MoreButton";
import { useDisclosure } from "@mantine/hooks";
import AlbumDescription from "./AlbumDescription";

const AlbumDetails = ({
  album,
  disabled,
  onUploadComplete,
  hasImages,
  loadData,
  fileUploadTooltip,
}: {
  hasImages?: boolean;
  album: Album;
  disabled?: boolean | undefined;
  onUploadComplete: () => void;
  totalImageCount?: number;
  loadData: () => Promise<number | void>;
  fileUploadTooltip?: string;
}) => {
  const t = useTranslations("Album");
  const locale = useLocale();
  const eventDate =
    album.eventDate &&
    moment(album.eventDate).locale(locale).format("MMMM DD, YYYY");
  const [popoverOpened, setPopoverOpened] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const [opened, { close, open }] = useDisclosure(false);
  const albumUrl = `${window.location.origin}/album/${album?.shortId}`;

  return (
    <>
      <ShareAlbumModal close={close} opened={opened} albumUrl={albumUrl} />
      <div className="px-4 md:px-0 w-full">
        <Text
          size="3xl"
          className="font-playfair font-bold text-center uppercase"
        >
          {album.name}
        </Text>
        {eventDate && (
          <Text className="font-playfair font-bold text-center uppercase">
            {eventDate}
          </Text>
        )}
        {album.description && <AlbumDescription album={album} />}
        <div
          className={`flex flex-col gap-4 md:w-[320px] mx-auto ${
            !album.description && "mt-10"
          }`}
        >
          {/* If allowed to upload show button */}
          {album.public && (
            <FileUpload
              resize={album.optimizeImages}
              tooltip={fileUploadTooltip}
              fullWidth
              album={album}
              disabled={disabled}
              onUploadComplete={onUploadComplete}
              loadData={loadData}
            />
          )}
          <div className="flex gap-4 flex-wrap">
            {album.displayPhotowallButton && (
              <Button
                disabled={!hasImages}
                title={t("photoWall")}
                onClick={() => {
                  const url = `/${locale}/wall/${album.shortId}`;
                  if (isSmallScreen) {
                    window.location.href = url; // Open in the same tab
                  } else {
                    window.open(url, "_blank");
                  }
                }}
                icon={<IconPlayerPlay stroke={3} size={16} />}
                color="black"
                type="outline"
                className="flex-1"
              />
            )}
            {album.displayMoreActionsButton && (
              <MoreButton
                hasImages={hasImages}
                album={album}
                onShareClick={() => open()}
                open={() => setPopoverOpened(true)}
                close={() => setPopoverOpened(false)}
                opened={popoverOpened}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlbumDetails;
