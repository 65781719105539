import { useLocale, useTranslations } from "use-intl";
import { Album } from "../../../types/album.type";
import { Button, FileUpload, LinkifyText, ShortLogo } from "../../../common";
import { Text } from "../../../common";
import moment from "moment/min/moment-with-locales";
import { Spoiler } from "@mantine/core";
import { getImageUrl } from "../../../lib/utils";
import { IconPlayerPlay } from "@tabler/icons-react";
import { useIsSmallScreen } from "../../../hooks";

const MemorialAlbumHeader = ({
  album,
  disabled,
  onUploadComplete,
  loadData,
  fileUploadTooltip,
}: {
  album: Album;
  disabled?: boolean | undefined;
  onUploadComplete: () => void;
  loadData: () => Promise<number | void>;
  fileUploadTooltip?: string;
}) => {
  const t = useTranslations("Album");

  const locale = useLocale();
  const thumbnailUrl =
    album.profileThumbnail && getImageUrl(album.profileThumbnail);

  const startDate = moment(album.yearsFrom)
    .locale(locale)
    .format("MMMM DD, YYYY");
  const endDate = moment(album.yearsTo).locale(locale).format("MMMM DD, YYYY");
  const isSmallScreen = useIsSmallScreen();

  return (
    <div className="max-w-[1100px] px-4 w-full mx-auto">
      <div className="h-[72px] flex items-center justify-between">
        {!album.removeBranding && <ShortLogo album={album} />}
        <div className="flex gap-4 justify-center items-center">
          {album?.public && (
            <FileUpload
              resize={album.optimizeImages}
              tooltip={fileUploadTooltip}
              album={album}
              disabled={disabled}
              onUploadComplete={onUploadComplete}
              loadData={loadData}
            />
          )}
          <Button
            title={t("photoWall")}
            onClick={() => {
              const url = `/${locale}/wall/${album.shortId}`;
              if (isSmallScreen) {
                window.location.href = url; // Open in the same tab
              } else {
                window.open(url, "_blank");
              }
            }}
            icon={<IconPlayerPlay size={24} />}
            color="black"
            type="outline"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-6 max-w-[700px] my-8">
        {thumbnailUrl && (
          <img
            src={thumbnailUrl}
            className="w-[180px] h-[180px] rounded-full"
          />
        )}
        <div>
          <Text className="font-playfair uppercase tracking-wider">
            {t("inLovingMemory")}
          </Text>
          <Text
            size="3xl"
            className="font-playfair uppercase font-bold tracking-wider"
          >
            {album.name}
          </Text>
          {album.yearsFrom && album.yearsTo && (
            <Text className="font-playfair tracking-wider">
              {startDate} - {endDate}
            </Text>
          )}
          <Spoiler
            className=" mt-6"
            maxHeight={96}
            showLabel={t("seeMore")}
            hideLabel={t("hide")}
            styles={{
              control: {
                color: "#222222",
                fontWeight: 500,
              },
            }}
          >
            <Text color="gray">
              <LinkifyText text={album.description ?? ""}></LinkifyText>
            </Text>
          </Spoiler>
        </div>
      </div>
    </div>
  );
};

export default MemorialAlbumHeader;
