import { useRef, useState, useEffect } from "react";
import { ScrollArea } from "@mantine/core";
import { Album } from "../types/album.type";
import HashtagBadge from "./HashtagBadge";
import { useTranslations } from "use-intl";

// Define the props for the HashtagSelector component
const HashtagSelector = ({
  album,
  selectedHashtags,
  onClick,
  disabled,
  className = "",
  withStroke = true,
  withSeeAll = false,
  wrap = true,
  customColorOverlay = true,
}: {
  album: Album;
  selectedHashtags: string[];
  onClick: (hashtag?: string) => void;
  disabled?: boolean;
  className?: string;
  withStroke?: boolean;
  withSeeAll?: boolean;
  wrap?: boolean;
  customColorOverlay?: boolean;
}) => {
  const t = useTranslations("Hashtags");
  // Create a ref for the scroll container
  const scrollRef = useRef<HTMLDivElement>(null);
  // State to control the visibility of the gradient overlay
  const [showGradient, setShowGradient] = useState(false);

  useEffect(() => {
    // Function to check if there's overflow and update gradient visibility
    const checkOverflow = () => {
      if (scrollRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
        // Show gradient if content width is greater than container width
        // and we're not scrolled all the way to the right
        setShowGradient(
          scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth
        );
      }
    };

    // Check for overflow initially and on window resize
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", checkOverflow);
  }, [album, wrap]); // Re-run effect if album or wrap prop changes

  // Handle scroll events to update gradient visibility
  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
      // Show gradient if we're not scrolled to the end (with a small buffer)
      setShowGradient(scrollLeft < scrollWidth - clientWidth - 10); // 10px buffer
    }
  };

  return (
    <div className="relative w-full">
      <ScrollArea
        offsetScrollbars
        viewportRef={scrollRef}
        onScrollPositionChange={handleScroll}
        className="w-full"
      >
        <div
          className={`
            flex ${wrap ? "flex-wrap" : "flex-nowrap"}
            gap-2 md:gap-4 ${className}
            ${!wrap ? "overflow-x-auto" : ""}
          `}
        >
          {withSeeAll && (
            <HashtagBadge
              withStroke={withStroke}
              disabled={disabled}
              selected={selectedHashtags.length === 0}
              onClick={() => onClick(undefined)}
              title={t("seeAll")}
            />
          )}
          {album?.hashtags?.map((ht, index) => (
            <HashtagBadge
              withStroke={withStroke}
              disabled={disabled}
              selected={selectedHashtags.includes(ht.id)}
              onClick={() => onClick(ht.id)}
              key={index}
              title={ht.title}
            />
          ))}
        </div>
      </ScrollArea>
      {!wrap && showGradient && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            width: "5rem",
            background: `linear-gradient(to left, ${
              customColorOverlay ? album.color : "#F7F7F7"
            }, transparent)`,
            pointerEvents: "none",
          }}
        />
      )}
    </div>
  );
};

export default HashtagSelector;
