import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useTranslations } from "use-intl";
import { Helmet } from "react-helmet-async";
import { Button, Premium, RoundedBox, Text, Title } from "../../common";
import { openHowTo } from "../../store/howto.reducer";
import { Plan } from "../../types/plan.enum";
import { CanvaEmbed } from "./components";
import { HowItWorksTemplates } from "../../common/HowItWorks";

const LetsHelp = ({ t }: { t: any }) => {
  return (
    <RoundedBox
      roundedOnSmallScreens={true}
      color="light-beige"
      className="mt-6"
    >
      <Text className=" font-semibold">{t("letsHelp")}</Text>
      <Text className="mt-2">{t("letsHelpDescription")}</Text>
      <div className="flex gap-4 flex-wrap mt-6">
        <Button
          onClick={() => {
            //Open Trustpilot page in new tab
            window.open(
              "https://www.trustpilot.com/review/rompolo.com",
              "_blank"
            );
          }}
          data-ph-capture-attribute-event="leaveReviewClickedTemplates"
          className=""
          type="outline"
          title={t("leaveAReview")}
        />
        <div className="flex gap-4 items-center">
          <Text wrap="nowrap">{t("followUsOn")}</Text>
          <div className="flex gap-4">
            <a
              href="https://www.instagram.com/rompolocom/"
              target="_blank"
              data-ph-capture-attribute-event="instagramClickedTemplates"
            >
              <img className="w-6 h-6" src="/instagram.png" />
            </a>
            <a
              href="https://www.facebook.com/rompolocom/"
              target="_blank"
              data-ph-capture-attribute-event="facebookClickedTemplates"
            >
              <img className="w-6 h-6" src="/facebook.png" />
            </a>
            <a
              href="https://www.pinterest.com/wwwrompolocom/"
              target="_blank"
              data-ph-capture-attribute-event="pinterestClickedTemplates"
            >
              <img className="w-6 h-6" src="/pinterest.png" />
            </a>
          </div>
        </div>
      </div>
    </RoundedBox>
  );
};

const TemplatesPage = () => {
  const album = useSelector((state: RootState) => state.album.album);
  const t = useTranslations("Templates");
  const dispatch = useDispatch();
  const openHowItWorksTemplates = () => {
    dispatch(openHowTo("templates"));
  };

  const isFree = album?.plan === Plan.free;

  return (
    <div className="px-6 max-w-[1248px]">
      <HowItWorksTemplates />
      <Helmet>
        <title>Rompolo - {t("metaTitle")}</title>
      </Helmet>
      <div className="flex justify-between gap-4 items-center flex-wrap">
        <div>
          <div className="flex gap-2 items-center">
            <Title wrap="nowrap" size="H4">
              {t("title")}
            </Title>
            {isFree && <Premium />}
          </div>
        </div>
        <div>
          <span
            className="cursor-pointer font-semibold whitespace-nowrap text-black hover:text-very-black"
            onClick={openHowItWorksTemplates}
          >
            {t("howItWorks")}
          </span>
        </div>
      </div>
      <div>
        <Text className="mt-2" color="gray">
          {t("description")}
        </Text>
      </div>
      {!isFree && <LetsHelp t={t} />}
      <div className="relative">
        {isFree && (
          <div className=" absolute inset-0 bg-light-gray/80 z-10"></div>
        )}
        <div className="mt-6">
          <Title className="font-semibold" size="H5">
            {t("forAlbums")}
          </Title>
          <Text color="gray" className="mt-2">
            {t("forAlbumsDescription")}
          </Text>
          <div className="flex flex-wrap gap-6 mt-4">
            <CanvaEmbed
              title={t("bannerFull")}
              paddingTop="32.5%"
              templateLink="https://www.canva.com/design/DAGKWSuucLg/Sthz5P-ZDIP3x_oEQYv60Q/view?utm_content=DAGKWSuucLg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGKWSuucLg/gWgJQfnMVwq4lPeOgJLK1w/view"
            />
            <CanvaEmbed
              title={t("bannerFull")}
              paddingTop="32.5%"
              templateLink="https://www.canva.com/design/DAGKXuB2kbk/nD5t-xByPu4W378T55WIQA/view?utm_content=DAGKXuB2kbk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGKXuB2kbk/1jXcTMmNUmXuFrO2SucPcg/view"
            />
            <CanvaEmbed
              title={t("bannerHalf")}
              paddingTop="65.6250%"
              templateLink="https://www.canva.com/design/DAGKXgai3ZU/t8v_WEhgYPjsQQDBeIV8nw/view?utm_content=DAGKXgai3ZU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGKXgai3ZU/OD_FkdjKH4sngraHBnxXTg/view"
            />
            <CanvaEmbed
              title={t("bannerHalf")}
              paddingTop="65.6250%"
              templateLink="https://www.canva.com/design/DAGKX30ADXo/KeiWIGQpC-d51r35p5Frxg/view?utm_content=DAGKX30ADXo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGKX30ADXo/Y97sa4T_Rivqx0zX-4I_6w/view"
            />
          </div>
        </div>
        <div className=" mt-14">
          <Title className="font-semibold" size="H5">
            {t("print")}
          </Title>
          <Text className="mt-2" color="gray">
            {t("printDescription")}
          </Text>
          <div className="flex flex-wrap gap-6 mt-4">
            <CanvaEmbed
              title={t("partyGame")}
              paddingTop="141%"
              templateLink="https://www.canva.com/design/DAGG_Ez3i0E/XPmlXj-in_WU_SUhLLF8cA/view?utm_content=DAGG_Ez3i0E&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGG_Ez3i0E/0HNlL4laXH4TZN13RSjTog/view"
            />
            <CanvaEmbed
              title={t("weddingGame")}
              paddingTop="141%"
              templateLink="https://www.canva.com/design/DAGGuV6xgRo/TiJjHbTuPNVeHv6hDKxZGA/view?utm_content=DAGGuV6xgRo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGGuV6xgRo/J0rcJMC0T23CZbL8_J3WOw/view"
            />
            <CanvaEmbed
              title={t("partyInvitation")}
              paddingTop="142%"
              templateLink="https://www.canva.com/design/DAGLq6HGPRg/PNlLzx-QD7KFw9bE00c_qA/view?utm_content=DAGLq6HGPRg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGLq6HGPRg/_KBox7Y0YX2sug8WpAHPGw/view"
            />
            <CanvaEmbed
              title={t("weddingGame")}
              paddingTop="140%"
              templateLink="https://www.canva.com/design/DAGGtJSx85Q/eABIgp1oYUNw8SQKySuGSw/view?utm_content=DAGGtJSx85Q&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGGtJSx85Q/sDMhxxtOpllmwvGe5atZ8g/view"
            />
            <CanvaEmbed
              title={t("weddingGame")}
              paddingTop="142%"
              templateLink="https://www.canva.com/design/DAGK7t9qS7g/uiQHOoA7Ta1fehuQqCKQBw/view?utm_content=DAGK7t9qS7g&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGK7t9qS7g/9y9mkwYIcwarbX7OhU7ZkQ/view"
            />
            <CanvaEmbed
              title={t("partyGame")}
              paddingTop="142%"
              templateLink="https://www.canva.com/design/DAGNKpeX7Uo/AzkKB117P0R7PdjIDoVuGg/view?utm_content=DAGNKpeX7Uo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGNKpeX7Uo/ojzNoZJi5PquPZizW9tRAQ/view"
            />
            <CanvaEmbed
              title={t("weddingGame")}
              paddingTop="142%"
              templateLink="https://www.canva.com/design/DAGK7q1BUBo/l5Oqie3c_-X__B85ZXEQRQ/view?utm_content=DAGK7q1BUBo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGK7q1BUBo/qVHQoz0TsQnN6Js8GWIxmw/view"
            />
            <CanvaEmbed
              title={t("partyGame")}
              paddingTop="142%"
              templateLink="https://www.canva.com/design/DAGPgZXf-t0/AQvrik8Rqwn40AoblsFdpQ/view?utm_content=DAGPgZXf-t0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              designUrl="https://www.canva.com/design/DAGPgZXf-t0/Af97l9u625bWos4qo1Q0KA/view"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatesPage;
